<template>
  <main class="table-container" title="">
    <table class="my-table">
      <thead class="my-thead">
        <tr id="lifecycleThead">
          <th class="my-th">
            <p class="-stb">Stage</p>
          </th>
          <th class="my-th">
            <p class="-stb">Start Date</p>
          </th>
          <th class="my-th">
            <p class="-stb">Deadline</p>
          </th>
          <th class="my-th">
            <p class="-stb">Stage Budget</p>
          </th>
          <th class="my-th">
            <p class="-stb">Construction Cost</p>
          </th>
          <th class="my-th">
            <p class="-stb">Stage Notes</p>
          </th>
          <th class="my-th"></th>
        </tr>
      </thead>
      <tbody
        v-for="(stage, index) in stages"
        :key="stage"
        :style="[`height: calc(100% / ${stages.length}) !important;`]"
      >
        <tr
          class="tr-content"
          :class="[
            index % 2 ? 'bg-primary' : 'bg-secondary',
            { 'current-stage': currentStage === stage.stage_value },
          ]"
        >
          <td>
            <div class="my-td" style="padding-left: 16px">
              <p class="-stb">{{ stage.stage_value }}</p>
            </div>
          </td>
          <td>
            <div class="my-td">
              <p class="-xstb">{{ stage.start_date }}</p>
            </div>
          </td>
          <td>
            <div class="my-td">
              <p class="-xstb">{{ stage.deadline }}</p>
            </div>
          </td>
          <td>
            <div class="my-td flex-center">
              <p class="-xstb">{{ stage.budget }} €</p>
            </div>
          </td>
          <td>
            <div class="my-td flex-center">
              <p class="-xstb">{{ stage.cost }} €</p>
            </div>
          </td>
          <td>
            <div class="my-td" style="justify-content: flex-start">
              <p class="-xstb">{{ stage.notes }}</p>
            </div>
          </td>
          <td>
            <div class="my-td">
              <IconEdit title="Edit Row" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import IconEdit from "../../../assets/icons/dynamicIcons/IconEdit.vue";
export default {
  props: ["stages"],
  components: { IconEdit },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["currentStage"]),
  },
};
</script>

<style scoped>
.table-container {
  height: 100%;
}
.my-table {
  height: 100%;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  min-width: 1023px;
}
.my-thead {
  height: 44px !important;
  white-space: nowrap;
}
.my-th {
  padding-inline: 16px;
}
.my-td {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.current-stage,
.current-stage:hover {
  transition: background 150ms ease;
  background: rgb(230, 247, 255) !important;
  background: linear-gradient(
    156deg,
    rgba(230, 247, 255, 1) 25%,
    rgba(215, 232, 255, 1) 100%
  ) !important;
}
</style>